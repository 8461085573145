var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "InvoiceBookingComponent d-flex flex-wrap",
      attrs: { "data-test-id": "invoice_booking-component" },
    },
    _vm._l(_vm.bookings, function (booking) {
      return _c(
        "div",
        {
          key: booking.bookingId,
          staticClass: "d-flex flex-wrap w-100 mt-2 mb-6 align-items-center",
        },
        [
          _c("h5", { staticClass: "mb-2 mr-2" }, [
            _vm._v(
              " " + _vm._s(_vm.$labels.InvoiceDetailsTab.booking.title) + " "
            ),
          ]),
          _c(
            "a",
            {
              staticClass: "emobg-link-primary emobg-body-2 mb-2 mr-2",
              attrs: {
                href: `/carsharing/bookings/${booking.uuid}`,
                target: "_blank",
                "data-test-id": "link",
              },
            },
            [
              _vm._v(" " + _vm._s(booking.id) + " "),
              _c("ui-icon", {
                staticClass: "ml-1",
                attrs: { icon: _vm.ICONS.externalUrl, size: _vm.SIZES.small },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(
              " - " +
                _vm._s(
                  _vm.reformatDateTime(
                    booking.startStr,
                    _vm.DATE_FORMAT.defaultExtended
                  )
                ) +
                " - " +
                _vm._s(
                  _vm.reformatDateTime(
                    booking.endStr,
                    _vm.DATE_FORMAT.defaultExtended
                  )
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "col-12 pl-0 mb-2" }, [
            _vm._v(" " + _vm._s(booking.location) + " "),
          ]),
          _c("div", { staticClass: "mb-2 mr-1" }, [
            _vm._v(" " + _vm._s(_vm.userFullName(booking.user)) + " - "),
          ]),
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(" " + _vm._s(booking.vehiclePlate) + " "),
          ]),
          _c("div", { staticClass: "col-12 pl-0 mb-2" }, [
            _vm._v(" " + _vm._s(booking.vehicle) + " "),
          ]),
          _vm.isDraftCreditNote
            ? _c(
                "InvoiceRefundableServiceLineComponent",
                _vm._g(
                  {
                    staticClass: "w-100 mb-6",
                    attrs: {
                      "draft-services": _vm.callBookingService(booking.id),
                    },
                  },
                  _vm.$listeners
                )
              )
            : _c(
                "InvoiceServiceLineComponent",
                _vm._g(
                  {
                    staticClass: "w-100",
                    attrs: {
                      "booking-id": booking.id,
                      "invoice-id": _vm.invoiceId,
                      "is-edit-allowed": _vm.isEditAllowed,
                    },
                  },
                  _vm.$listeners
                )
              ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }